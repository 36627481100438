import { EXT_LINK } from '@services/http/ExtLink';

export const getRoute = (route, ...params) => {
    if (!params || !params.length) {
        return route;
    }
    const uri = params.join('');
    return `${route}/${uri}`;
};

export const getOldRoute = (...params) => {
    return getRoute(process.env.NEXT_PUBLIC_BACKEND_URL, ...params);
};

export const getFullRoute = (endPoint) => {
    return `${process.env.FRONTEND_BASE_URL}${endPoint}`;
};

export const getSubdomainRoute = (subdomain) => {
    return `http${process.env.NODE_ENV === 'development' ? '' : 's'}://${subdomain}.${process.env.BASE_URL}${
        process.env.NODE_ENV === 'development' ? ':' + process.env.BACKEND_PORT : ''
    }`;
};

export const ROUTE = {
    LPC: {
        HOME: '/',
        SIGN_IN: '/connexion',
        FAQ: '/foire-aux-questions',
        ACTIVITY_REPORT: '/fe/rapport-activite-2022',
        PARTNERS: '/partenaires',
        CGU: '/conditions-generales-d-utilisation',
        CGU_XPOLLENS: '/front-static/pdf/cgu_xpollens.pdf',
        DONATION_CONTRACT: '/front-static/pdf/contrat-type-don-lpc.pdf',
        PRIVACY_POLITY: '/politique-de-confidentialit%C3%A9',
        COOKIE: '/utilisation-des-cookies',
        ODVP: '/on-double-votre-pot-2022',
        OSFLM: '/on-se-fait-la-malle-2022',
        OSFLM_FORM: '/on-se-fait-la-malle-2022/mini-questionnaire',
        OSFLM_PARTICIPATED: '/on-se-fait-la-malle-2022/confirmation',
        OSFLM_FINISHED: '/on-se-fait-la-malle-2022/jeu-concours-termine',
        SECURITY: '/securite',
        CONTEST: '/jeu-concours/',
        PURCHASING_TYPE: {
            PARTNERS_NETWORKS: '/partenaires',
            ONE_PARTNER: '/partenaire',
            GIFT_CARD: '/cado-carte',
            BANK_TRANSFER: '/virement-bancaire',
        },
        DASHBOARD: {
            MY_PARAMS: '/tableau-de-bord/mes-parametres',
            MY_PROFILE: '/tableau-de-bord/mon-profil',
            MY_DOCUMENTS: '/tableau-de-bord/mes-documents',
            MY_KYC: '/tableau-de-bord/mes-kyc',
            MY_COMMUNICATION: '/tableau-de-bord/communication',
            MY_LISTS: '/tableau-de-bord/mes-listes',
            MY_POTS_CREATED: '/tableau-de-bord/mes-pots/crees',
            MY_LISTS_CREATED: '/tableau-de-bord/mes-listes',
            MY_POTS_RECEIVED: '/tableau-de-bord/mes-pots/recus',
            MY_PARTICIPATIONS: '/tableau-de-bord/mes-participations',
            MY_SPENDINGS: '/tableau-de-bord/mes-depenses',
            MY_NOTIFICATIONS: '/tableau-de-bord/mes-notifications',
        },
        SETTINGS: process.env.NEXT_PUBLIC_BACKEND_URL + '/tableau-de-bord-mon-profil',
        POT: {
            CREATE_POT: '/creer-un-pot-commun',
            POT: '/pot',
            SUCCESS_POT: '/pot-cree',
            PAYMENT: '/participer/',
            PAYMENT_SUCCESS: '/paiement-valide/',
            SPENDING: '/utiliser',
            GIFT_CARD: '/cadocarte/',
            GIFT_CARD_ORDER: 'commander',
            GIFT_CARD_CREDIT: '/credit',
            OFFER: '/offrir',
            SPENDING_TRANSFER: '/collect',
            SPENDING_CADO_CARD: '/cado-carte',
            SPENDING_PARTNER: '/partenaires',
            SPENDING_PARTNER_E_CARD: '/creer-une-carte-cadeau-',
            SPENDING_PARTNER_LIMONETIK: '/paiement-via-pot-commun-choix',
            SPENDING_PARTNER_E_CARD_CONFIRMATION: '/e-carte/',
            GREETING_CARD: '/carte-de-voeux',
            BEREAVEMENT_CARD: '/carte-de-soutien',
        },
        AUTH: {
            SIGN_IN: '/connexion',
            SIGN_IN_PARTICIPATE: '/connexion?potRef=',
            SIGN_UP: '/inscription',
            TFA_UPDATE: '/double-authentification/modifier',
            BACKUP_CODE: '/double-authentification/backup-codes',
            TFA: '/double-authentification',
        },
        HELP_CENTER: {
            MAIN: '/centre-aide',
            RESULT: '/centre-aide/resultats',
            DETAILS: '/centre-aide/foire-aux-questions',
            CONTACT: '/centre-aide/contact',
        },
    },
    MPP: {
        HOME: '/mon-petit-placement',
        CGU: 'https://www.monpetitplacement.fr/fr/preparer-l-avenir-de-mon-enfant',
    },
    DASHBOARD: {
        SIGN_IN: '/dashboard/connexion',
        STATS: '/dashboard/stats',
        INFORMATIONS: '/dashboard/informations',
        PREVIEW: '/dashboard/preview',
        ADMIN: {
            PARTNERS: '/dashboard/admin/partners',
            PREVIEW: '/dashboard/admin/preview',
            PREVIEW_IFRAME: '/dashboard/admin/preview-iframe',
            INFORMATIONS: '/dashboard/admin/informations',
            STATS: '/dashboard/admin/stats',
        },
    },
    PRESS_AREA: EXT_LINK.PRESS,
    CONTEST: process.env.NEXT_PUBLIC_BACKEND_URL + '/jeu-concours',
    SMALL_LISTS: process.env.NEXT_PUBLIC_BACKEND_URL + '/les-petites-listes',
    WEDDING_LIST: process.env.NEXT_PUBLIC_BACKEND_URL + '/creer-ma-liste-de-mariage#from-pot-creation',
    BIRTH_LIST: process.env.NEXT_PUBLIC_BACKEND_URL + '/creer-ma-liste-de-naissance#from-pot-creation',
    MY_DOCUMENTS: process.env.NEXT_PUBLIC_BACKEND_URL + '/tableau-de-bord-mes-documents',
    MY_KYC: process.env.NEXT_PUBLIC_BACKEND_URL + '/tableau-de-bord-kyc',
};
